.game-screen {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .GameScreenAlt .App-header {
    display: none;
  }
  
  .access-code-card {
    position: absolute; /* Position the card absolutely */
    top: 10px; /* Adjust the top position as needed */
    left: 50%; /* Position it 50% from the left */
    transform: translateX(-50%); /* Center it horizontally */
    z-index: 1; /* Ensure the card is above the stars animation */
}


  .logo-overlay {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1; /* Ensure the logo overlay is on top of the canvas */
  }
  
  .logo {
    max-width: 100%;
    max-height: 100%;
  }